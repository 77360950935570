import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { mobile, tablet, BlurFilter } from '~styles/global'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Image from '~components/Image'

const About = ({ data }) => {
	const { title, content } = data.page
	const {
		siteTitle,
		phoneNumber,
		email,
		instagramLink,
		vimeoLink,
	} = useSiteSettings()

	return (
		<>
			<Global styles={css`
				:root{
					--olive: #ffffff;
					--olivetrans: rgba(255, 255, 255, 0);
				}
			`} />
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<BlurFilter />
			<Content>
				<LeftCol>
					<h2>About</h2>
					<div className="h3">
						<RichText content={content?.bio}/>
					</div>
					<SmallText>
						<h5>Selected Press</h5>
						<RichText content={content?.selectedPress}/>
					</SmallText>
					<SmallText>
						<h5>Selected Awards</h5>
						<RichText content={content?.selectedAwards}/>
					</SmallText>
				</LeftCol>
				<RightCol>
					<div>
						<h2>Contact</h2>
						<Contact className="h4">
							{siteTitle}<br/>
							{phoneNumber &&
							<><a className="hover-underline" href={`tel:${phoneNumber}`}>{phoneNumber}</a><br/></>
							}
							{email &&
							<><a className="hover-underline" href={`mailto:${email}`}>{email}</a><br/></>
							}
						</Contact>
						<Links className="h4">
							{instagramLink &&
							<><a className="hover-underline" href={instagramLink} target="_blank" rel="noreferrer">Instagram</a><br/></>
							}
							{vimeoLink &&
							<><a className="hover-underline" href={vimeoLink} target="_blank" rel="noreferrer">Vimeo</a><br/></>
							}
						</Links>
					</div>
					<Portrait image={content.portrait} />
				</RightCol>
			</Content>
			<DataViewer data={data} name="data"/>
		</>
	)
}

const LeftCol = styled.div`
	grid-column: span 8;
	${mobile}{
		grid-column: span 12;
	}
`

const RightCol = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		margin-top: var(--xxl);
	}
`

const Contact = styled.div`
	margin-bottom: var(--xs);
`

const Links = styled.div`
	
`

const Portrait = styled(Image)`
	margin-top: var(--xl);
	${mobile}{
		margin-top: 0;
	}
`

const Content = styled(Section)`
	margin: var(--xxxxxl) 0;
	h2{
		margin-bottom: var(--s);
	}
	div.h3{
		margin-bottom: var(--xxl);
		/* p:first-of-type{
			text-indent: 16.6666vw;
		} */
		p{
			margin-bottom: 0;
			text-indent: 16.6666vw;
		}
	}	
`

const SmallText = styled.div`
	max-width: 570px;
	margin-bottom: var(--s);
	h5{
		margin-left: 16.6666vw;
	}
`

export const query = graphql`
  query AboutQuery {
    page: sanityAboutPage {
      title
      content {
				bio: _rawBio
				selectedPress: _rawSelectedPress
				selectedAwards: _rawSelectedAwards
				portrait{
					...imageWithAlt
				}
        seo{
					...seo
				}
      }
    }
  }
`

About.propTypes = {
	data: PropTypes.object,
}

export default About
